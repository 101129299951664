import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  // walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createConfig } from 'wagmi';
import { storyTestnet } from 'wagmi/chains';
import generateHttpEndpoint, { CHAINS } from './utils/generateHttpEndpoint';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        // walletConnectWallet,
        coinbaseWallet,
      ],
    },
  ],
  {
    projectId: 'be0178696f4878d05b858efc64ccb2fd',
    appName: 'Spotlight Protocol',
  }
);

export const config = createConfig({
  chains: CHAINS,
  multiInjectedProviderDiscovery: false,
  connectors,
  ssr: true,
  transports: {
    [storyTestnet.id]: generateHttpEndpoint(storyTestnet.id),
  },
});

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}
